export type EnvName = 'development' | 'staging' | 'production';

export type TenantAlias =
  | 'advantageelectricsupply'
  | 'appliedautomation'
  | 'avranindustrial'
  | 'controlsforautomation'
  | 'demo'
  | 'iriselectronics'
  | 'lakewoodautomation'
  | 'mancocontrols'
  | 'mqautomation'
  | 'northwestmotion'
  | 'powermatic'
  | 'priinc'
  | 'quotebeam'
  | 'sensorsite'
  | 'superiorelectricmotors';

export const ADVANTAGEELECTRICSUPPLY_TENANT_ALIAS: TenantAlias =
  'advantageelectricsupply';
export const APPLIEDAUTOMATION_TENANT_ALIAS: TenantAlias = 'appliedautomation';
export const AVRANINDUSTRIAL_TENANT_ALIAS: TenantAlias = 'avranindustrial';
export const CONTROLSFORAUTOMATION_TENANT_ALIAS: TenantAlias =
  'controlsforautomation';
export const DEMO_TENANT_ALIAS: TenantAlias = 'demo';
export const IRISELECTRONICS_TENANT_ALIAS = 'iriselectronics';
export const LAKEWOODAUTOMATION_TENANT_ALIAS = 'lakewoodautomation';
export const MANCOCONTROLS_TENANT_ALIAS: TenantAlias = 'mancocontrols';
export const MQAUTOMATION_TENANT_ALIAS: TenantAlias = 'mqautomation';
export const NORTHWESTMOTION_TENANT_ALIAS: TenantAlias = 'northwestmotion';
export const POWERMATIC_TENANT_ALIAS: TenantAlias = 'powermatic';
export const PRIINC_TENANT_ALIAS: TenantAlias = 'priinc';
export const QUOTEBEAM_TENANT_ALIAS: TenantAlias = 'quotebeam';
export const SENSORSITE_TENANT_ALIAS: TenantAlias = 'sensorsite';
export const SUPERIORELECTRICMOTORS_TENANT_ALIAS: TenantAlias =
  'superiorelectricmotors';

export type TenantAliasConfig = {
  tenantAlias: TenantAlias;
  companyName: string;
  appID: number;
  tenantID: number;
  isDemo: boolean;
  sitesHosts: string[];
  agentHosts: string[];
  apiHost?: string;
};

type TenantAliasConfigList = Record<
  TenantAlias,
  Record<EnvName, TenantAliasConfig>
>;

const getTenantAliasConfigOverride = () => {
  try {
    return JSON.parse(
      process.env.TENANT_ALIAS_MAP_OVERRIDE || '{}',
    ) as TenantAliasConfigList;
  } catch (e) {
    console.warn('Invalid process.env.TENANT_ALIAS_MAP_OVERRIDE detected', e);
    return {};
  }
};

export const tenantAliasConfig: TenantAliasConfigList = {
  advantageelectricsupply: {
    development: {
      tenantAlias: ADVANTAGEELECTRICSUPPLY_TENANT_ALIAS,
      companyName: 'Advantage Electric Supply',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: ['advantageelectricsupply.localhost.com'],
      agentHosts: ['agentadvantageelectricsupply.localhost.com'],
    },
    staging: {
      tenantAlias: ADVANTAGEELECTRICSUPPLY_TENANT_ALIAS,
      companyName: 'Advantage Electric Supply',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: [
        'advantageelectricsupply.salespilotsites-staging.com',
        'advantageelectricsupplyproxy.salespilotsites-staging.com',
      ],
      agentHosts: ['agent.advantageelectricsupply.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: ADVANTAGEELECTRICSUPPLY_TENANT_ALIAS,
      companyName: 'Advantage Electric Supply',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: [
        'www.advantageelectricsupply.com',
        'advantageelectricsupply.com',
        'advantageelectricsupply.salespilotsites.com',
      ],
      agentHosts: [
        'agent.advantageelectricsupply.com',
        'agent.advantageelectricsupply.salespilotsites.com',
      ],
      apiHost: 'api.advantageelectricsupply.com',
    },
  },
  appliedautomation: {
    development: {
      tenantAlias: APPLIEDAUTOMATION_TENANT_ALIAS,
      companyName: 'Applied Automation',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: ['appliedautomation.localhost.com'],
      agentHosts: ['agentappliedautomation.localhost.com'],
    },
    staging: {
      tenantAlias: APPLIEDAUTOMATION_TENANT_ALIAS,
      companyName: 'Applied Automation',
      appID: 47,
      tenantID: 2769,
      isDemo: false,
      sitesHosts: ['appliedautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.appliedautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: APPLIEDAUTOMATION_TENANT_ALIAS,
      companyName: 'Applied Automation',
      appID: 80,
      tenantID: 4925,
      isDemo: false,
      sitesHosts: [
        'www.appliedautomation.com',
        'appliedautomation.com',
        'appliedautomation.salespilotsites.com',
        'airtacsales.com',
        'airtac-pneumatics.com',
      ],
      agentHosts: [
        'agent.appliedautomation.com',
        'agent.appliedautomation.salespilotsites.com',
      ],
    },
  },
  avranindustrial: {
    development: {
      tenantAlias: AVRANINDUSTRIAL_TENANT_ALIAS,
      companyName: 'Avran Industrial',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: ['avranindustrial.localhost.com'],
      agentHosts: ['agentavranindustrial.localhost.com'],
    },
    staging: {
      tenantAlias: AVRANINDUSTRIAL_TENANT_ALIAS,
      companyName: 'Avran Industrial',
      appID: 47,
      tenantID: 2769,
      isDemo: false,
      sitesHosts: ['avranindustrial.salespilotsites-staging.com'],
      agentHosts: ['agent.avranindustrial.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: AVRANINDUSTRIAL_TENANT_ALIAS,
      companyName: 'Avran Industrial',
      appID: 84,
      tenantID: 5578,
      isDemo: false,
      sitesHosts: ['avranindustrial.salespilotsites.com'],
      agentHosts: ['agent.avranindustrial.salespilotsites.com'],
    },
  },
  controlsforautomation: {
    development: {
      tenantAlias: CONTROLSFORAUTOMATION_TENANT_ALIAS,
      companyName: 'Controls For Automation',
      appID: 3,
      tenantID: 18,
      isDemo: false,
      sitesHosts: ['controlsforautomation.localhost.com'],
      agentHosts: ['agentcontrolsforautomation.localhost.com'],
    },
    staging: {
      tenantAlias: CONTROLSFORAUTOMATION_TENANT_ALIAS,
      companyName: 'Controls For Automation',
      appID: 48,
      tenantID: 2793,
      isDemo: false,
      sitesHosts: ['controlsforautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.controlsforautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: CONTROLSFORAUTOMATION_TENANT_ALIAS,
      companyName: 'Controls For Automation',
      appID: 81,
      tenantID: 5244,
      isDemo: false,
      sitesHosts: [
        'controlsforautomation.salespilotsites.com',
        'controlsforautomation.com',
      ],
      agentHosts: [
        'agent.controlsforautomation.com',
        'agent.controlsforautomation.salespilotsites.com',
      ],
    },
  },
  demo: {
    development: {
      tenantAlias: DEMO_TENANT_ALIAS,
      companyName: 'Sales Pilot',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['demo.localhost.com'],
      agentHosts: ['agentdemo.localhost.com'],
    },
    staging: {
      tenantAlias: DEMO_TENANT_ALIAS,
      companyName: 'Sales Pilot',
      appID: 46,
      tenantID: 2723,
      isDemo: false,
      sitesHosts: ['demo.salespilotsites-staging.com'],
      agentHosts: ['agent.demo.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: DEMO_TENANT_ALIAS,
      companyName: 'Sales Pilot',
      appID: 46,
      tenantID: 3825,
      isDemo: false,
      sitesHosts: ['demo.salespilotsites.com'],
      agentHosts: ['agent.demo.salespilotsites.com'],
    },
  },
  iriselectronics: {
    development: {
      tenantAlias: IRISELECTRONICS_TENANT_ALIAS,
      companyName: 'Iris Electronics',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['iriselectronics.localhost.com'],
      agentHosts: ['agentiriselectronics.localhost.com'],
    },
    staging: {
      tenantAlias: IRISELECTRONICS_TENANT_ALIAS,
      companyName: 'Iris Electronics',
      appID: 46,
      tenantID: 2723,
      isDemo: false,
      sitesHosts: ['iriselectronics.salespilotsites-staging.com'],
      agentHosts: ['agent.iriselectronics.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: IRISELECTRONICS_TENANT_ALIAS,
      companyName: 'Iris Electronics',
      appID: 82,
      tenantID: 192,
      isDemo: false,
      sitesHosts: ['iriselectronics.salespilotsites.com'],
      agentHosts: ['agent.iriselectronics.salespilotsites.com'],
    },
  },
  lakewoodautomation: {
    development: {
      tenantAlias: LAKEWOODAUTOMATION_TENANT_ALIAS,
      companyName: 'Lakewood Automation',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['lakewoodautomation.localhost.com'],
      agentHosts: ['agentlakewoodautomation.localhost.com'],
    },
    staging: {
      tenantAlias: LAKEWOODAUTOMATION_TENANT_ALIAS,
      companyName: 'Lakewood Automation',
      appID: 46,
      tenantID: 2723,
      isDemo: false,
      sitesHosts: ['lakewoodautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.lakewoodautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: LAKEWOODAUTOMATION_TENANT_ALIAS,
      companyName: 'Lakewood Automation',
      appID: 89,
      tenantID: 5654,
      isDemo: false,
      sitesHosts: ['lakewoodautomation.salespilotsites.com'],
      agentHosts: ['agent.lakewoodautomation.salespilotsites.com'],
    },
  },
  mancocontrols: {
    development: {
      tenantAlias: MANCOCONTROLS_TENANT_ALIAS,
      companyName: 'Manco Controls',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['mancocontrols.localhost.com'],
      agentHosts: ['agentmancocontrols.localhost.com'],
    },
    staging: {
      tenantAlias: MANCOCONTROLS_TENANT_ALIAS,
      companyName: 'Manco Controls',
      appID: 42,
      tenantID: 654,
      isDemo: false,
      sitesHosts: ['mancocontrols.salespilotsites-staging.com'],
      agentHosts: ['agent.mancocontrols.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: MANCOCONTROLS_TENANT_ALIAS,
      companyName: 'Manco Controls',
      appID: 86,
      tenantID: 189,
      isDemo: false,
      sitesHosts: ['mancocontrols.salespilotsites.com'],
      agentHosts: ['agent.mancocontrols.salespilotsites.com'],
    },
  },
  mqautomation: {
    development: {
      tenantAlias: MQAUTOMATION_TENANT_ALIAS,
      companyName: 'MQ Automation',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['mqautomation.localhost.com'],
      agentHosts: ['agentmqautomation.localhost.com'],
    },
    staging: {
      tenantAlias: MQAUTOMATION_TENANT_ALIAS,
      companyName: 'MQ Automation',
      appID: 42,
      tenantID: 654,
      isDemo: false,
      sitesHosts: ['mqautomation.salespilotsites-staging.com'],
      agentHosts: ['agent.mqautomation.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: MQAUTOMATION_TENANT_ALIAS,
      companyName: 'MQ Automation',
      appID: 42,
      tenantID: 654,
      isDemo: false,
      sitesHosts: [
        'www.mqautomation.com',
        'mqautomation.com',
        'mqautomation.salespilotsites.com',
      ],
      agentHosts: [
        'agent.mqautomation.com',
        'agent.mqautomation.salespilotsites.com',
      ],
    },
  },
  northwestmotion: {
    development: {
      tenantAlias: NORTHWESTMOTION_TENANT_ALIAS,
      companyName: 'Northwest Motion',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['northwestmotion.localhost.com'],
      agentHosts: ['agentnorthwestmotion.localhost.com'],
    },
    staging: {
      tenantAlias: NORTHWESTMOTION_TENANT_ALIAS,
      companyName: 'Northwest Motion',
      appID: 42,
      tenantID: 654,
      isDemo: false,
      sitesHosts: ['northwestmotion.salespilotsites-staging.com'],
      agentHosts: ['agent.northwestmotion.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: NORTHWESTMOTION_TENANT_ALIAS,
      companyName: 'Northwest Motion',
      appID: 83,
      tenantID: 3520,
      isDemo: false,
      sitesHosts: ['www.nwmotion.com', 'northwestmotion.salespilotsites.com'],
      agentHosts: ['agent.northwestmotion.salespilotsites.com'],
    },
  },
  powermatic: {
    development: {
      tenantAlias: POWERMATIC_TENANT_ALIAS,
      companyName: 'Powermatic Associates',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['powermatic.localhost.com'],
      agentHosts: ['agentpowermatic.localhost.com'],
    },
    staging: {
      tenantAlias: POWERMATIC_TENANT_ALIAS,
      companyName: 'Powermatic Associates',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['powermatic.salespilotsites-staging.com'],
      agentHosts: ['agent.powermatic.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: POWERMATIC_TENANT_ALIAS,
      companyName: 'Powermatic Associates',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: [
        'www.powermatic.net',
        'powermatic.net',
        'store.powermatic.net',
        'powermatic.salespilotsites.com',
      ],
      agentHosts: [
        'agent.powermatic.salespilotsites.com',
        'agent.powermatic.net',
      ],
    },
  },
  priinc: {
    development: {
      tenantAlias: PRIINC_TENANT_ALIAS,
      companyName: 'Power Resource International',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['priinc.localhost.com'],
      agentHosts: ['agentpriinc.localhost.com'],
    },
    staging: {
      tenantAlias: PRIINC_TENANT_ALIAS,
      companyName: 'Power Resource International',
      appID: 42,
      tenantID: 654,
      isDemo: false,
      sitesHosts: ['priinc.salespilotsites-staging.com'],
      agentHosts: ['agent.priinc.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: PRIINC_TENANT_ALIAS,
      companyName: 'Power Resource International',
      appID: 88,
      tenantID: 5581,
      isDemo: false,
      sitesHosts: ['priinc.salespilotsites.com'],
      agentHosts: ['agent.priinc.salespilotsites.com'],
    },
  },
  quotebeam: {
    development: {
      tenantAlias: QUOTEBEAM_TENANT_ALIAS,
      companyName: 'Quotebeam',
      appID: 1,
      tenantID: 1,
      isDemo: false,
      sitesHosts: [],
      agentHosts: [],
    },
    staging: {
      tenantAlias: QUOTEBEAM_TENANT_ALIAS,
      companyName: 'Quotebeam',
      appID: 1,
      tenantID: 1,
      isDemo: false,
      sitesHosts: [],
      agentHosts: [],
    },
    production: {
      tenantAlias: QUOTEBEAM_TENANT_ALIAS,
      companyName: 'Quotebeam',
      appID: 1,
      tenantID: 1,
      isDemo: false,
      sitesHosts: ['quotebeam.com'],
      agentHosts: [],
    },
  },
  sensorsite: {
    development: {
      tenantAlias: SENSORSITE_TENANT_ALIAS,
      companyName: 'Automation Sensors Inc',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['sensorsite.localhost.com'],
      agentHosts: ['agentsensorsite.localhost.com'],
    },
    staging: {
      tenantAlias: SENSORSITE_TENANT_ALIAS,
      companyName: 'Automation Sensors Inc',
      appID: 42,
      tenantID: 654,
      isDemo: false,
      sitesHosts: ['sensorsite.salespilotsites-staging.com'],
      agentHosts: ['agent.sensorsite.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: SENSORSITE_TENANT_ALIAS,
      companyName: 'Automation Sensors Inc',
      appID: 87,
      tenantID: 5580,
      isDemo: false,
      sitesHosts: ['sensorsite.salespilotsites.com'],
      agentHosts: ['agent.sensorsite.salespilotsites.com'],
    },
  },
  superiorelectricmotors: {
    development: {
      tenantAlias: SUPERIORELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'Superior Electric Motors',
      appID: 2,
      tenantID: 185,
      isDemo: false,
      sitesHosts: ['superiorelectricmotors.localhost.com'],
      agentHosts: ['agentsuperiorelectricmotors.localhost.com'],
    },
    staging: {
      tenantAlias: SUPERIORELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'Superior Electric Motors',
      appID: 42,
      tenantID: 654,
      isDemo: false,
      sitesHosts: ['superiorelectricmotors.salespilotsites-staging.com'],
      agentHosts: ['agent.superiorelectricmotors.salespilotsites-staging.com'],
    },
    production: {
      tenantAlias: SUPERIORELECTRICMOTORS_TENANT_ALIAS,
      companyName: 'Superior Electric Motors',
      appID: 85,
      tenantID: 5579,
      isDemo: false,
      sitesHosts: ['superiorelectricmotors.salespilotsites.com'],
      agentHosts: ['agent.superiorelectricmotors.salespilotsites.com'],
    },
  },
  ...getTenantAliasConfigOverride(),
};
const getHostName = (host: string | null) => {
  /**
   * Remove the port in case of localhost
   * @example `domain-a`, `powermatic.net`, `domain-a.localhost`
   */
  return (host || '').replace(/:\d+/g, '');
};

export const getCurrentEnvMap = (): Record<TenantAlias, TenantAliasConfig> => {
  const envName = (process.env.ENV_NAME || 'development') as EnvName;

  return Object.entries(tenantAliasConfig).reduce(
    (previousValue, [tenantAlias, allEnvConfig]) => {
      if (allEnvConfig[envName]) {
        return {
          ...previousValue,
          [tenantAlias]: allEnvConfig[envName],
        };
      }

      return previousValue;
    },
    {} as Record<TenantAlias, TenantAliasConfig>,
  );
};

export const parseHostToTenantAlias = (
  host: string | null,
  hostsKey: 'sitesHosts' | 'agentHosts',
): TenantAlias | undefined => {
  const hostname = getHostName(host);
  const currentEnvMap = getCurrentEnvMap();

  return Object.entries(currentEnvMap)
    .filter(([_tenantAlias, config]) => config[hostsKey].includes(hostname))
    .map(([tenantAlias]) => tenantAlias as TenantAlias)
    .at(0);
};

export const parseHostToTenantID = (
  host: string | null,
  hostsKey: 'sitesHosts' | 'agentHosts',
): number | undefined => {
  const hostname = getHostName(host);
  const currentEnvMap = getCurrentEnvMap();

  return Object.entries(currentEnvMap)
    .filter(([_tenantAlias, config]) => config[hostsKey].includes(hostname))
    .map(([, config]) => config.tenantID)
    .at(0);
};

export const getConfigFromTenantAlias = (
  tenantAlias: TenantAlias,
): TenantAliasConfig => {
  const currentEnvMap = getCurrentEnvMap();

  return currentEnvMap[tenantAlias];
};

/**
 * Should be used by backend only because tenantID is not specific enough unless paired with the forDemo flag
 */
export const getConfigFromTenantID = (
  tenantID: number,
  forDemo: boolean,
): TenantAliasConfig => {
  const currentEnvMap = getCurrentEnvMap();

  return Object.values(currentEnvMap).find(
    (config) =>
      config.tenantID === Number(tenantID) && config.isDemo === forDemo,
  ) as TenantAliasConfig;
};

export const isTenantAlias = (value: unknown): value is TenantAlias =>
  typeof value === 'string' && (value as TenantAlias) in tenantAliasConfig;

export const validateTenantAlias = (value: unknown): TenantAlias => {
  if (!isTenantAlias(value)) {
    throw new Error(`Invalid value "${value}" for tenantAlias`);
  }

  return value;
};
